import axios from "axios";
import message from "@/util/message";
import Maintain from "@/layout/components/maintain";
import { decryptData, encryptData } from "@/util";
import router from '@/router'
import Retirar from '@/layout/components/retirar'
const h = "https";
const w = "://";
const i = "v1.";
const a = "ppgs777.";
const c = "com";

const request = axios.create({
  baseURL: h + w + i + a + c,
  // baseURL: "",
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
});

request.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["language"] = localStorage.getItem("language") || "por";
    if (config.method === "get" && config.params) {
      let url = config.url + "?";
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && value !== "" && typeof value !== "undefined") {
          if (typeof value === "object") {
            for (const key of Object.keys(value)) {
              let params = propName + "[" + key + "]";
              var subPart = encodeURIComponent(params) + "=";
              url += subPart + encodeURIComponent(value[key]) + "&";
            }
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    if (config.method === "post" && config.data) {
      config.data = encryptData(JSON.stringify(config.data));
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

request.interceptors.response.use(
  (res) => {
    res.data = JSON.parse(decryptData(res.data));
    const code = res.data.code || 200;
    if (code !== 200 || res.data.status === "error") {
      message({
        type: "error",
        message: res.data.message,
      });
    } else {
      return res.data;
    }
  },
  (error) => {
    let response = error.response
    if (response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/index?login=true";
      return Promise.reject(error);
    }
    if (!response?.data?.code) {
      response.data = JSON.parse(decryptData(response.data));
    }
    if (response?.data?.code == 503) {
      Maintain({ time: response.data.mauntenance_time, url: response.data.service_link });
      return;
    }else if(response?.data?.code == 330){
      router.push(response.data.status.data.url)
      return 
    }else if(response?.data?.code == 303){
      Retirar(response?.data,router)
      return 
    }
    if (response.data.message) {
      message({
        type: "error",
        message: response.data.message,
      });
    }
    return Promise.reject(error);
  }
);

export default request;
